<template>
  <div class="cont">
    <div class="mount">
      <div class="mmmmm">
        <div class="top"></div>
        <div class="down"></div>
        <!-- Swiper -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item) in list"
              :key="item.id"
              @click="showInfo(item)"
            >
              <div class="text">
                <h2>{{ item.name }}</h2>
                <p>{{ item.type }}</p>
              </div>
              <div class="img">
                <img :src="item.src" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uav_img" ref="uavImg">
      <!-- <div class="img">
        <img :src="infoList.src" alt="" />
      </div> -->
      <div class="uav_video">
        <video id="mmcvideo" :src="infoList.video_url" loop controls></video>
        <!-- <video id="mmcvideo" :autoplay="true" src="./1.mp4"  loop controls ></video> -->
      </div>
    </div>
    <div class="right" ref="rightRef">
        <!-- <video src="./1.mp4" :autoplay="true" loop controls ></video> -->
      <!-- <div class="uav_video">
        <video :src="infoList.video_url" :autoplay="true" loop controls></video>
      </div> -->
      <div class="uav_info">
        <div class="title">入云龙2</div>
        <div class="info">
          <div class="info-text">
            <div>
              <p>机翼类型</p>
              <span>六旋翼</span>
            </div>
            <div>
              <p>高度</p>
              <span>510mm</span>
            </div>
            <div>
              <p>轴距</p>
              <span>1550mm</span>
            </div>
            <div>
              <p>净重</p>
              <span>6.26kg</span>
            </div>
          </div>
          <div class="info-img">
            <img src="~@/assets/new_yingji/UAV.png" alt="" />
          </div>
        </div>
        <div class="mount_info" v-show="show">
          <h3>{{ infoList.name }}</h3>
          <div class="mount_text">
            <div v-for="(item, i) in infoLists" :key="i">
              <p>{{ item[0] }}</p>
              <span>{{ item[1] }}</span>
            </div>
          </div>
        </div>
        <div
          v-show="!show"
          style="text-align: center; margin-top: 20px; font-size: 20px"
        >
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "./css/js/index.js";
export default {
  data() {
    return {
      list: [
        {
          id: 13,
          name: "飞鹰取水器",
          src: require("@/assets/images/observe/001.png"),
          尺寸: "170*160*210",
          重量: "1750g",
          钢丝长度: "5m",
          采水高度: "1-2m",
          桶直径: "160mm",
          供电电压: "24v",
          控制方式: "遥控器、平台",
          收放时间: "≤60s",
          video_url:"https://32.128.6.48/uploads/2023/11/14/197c12e2-714f-4c15-b939-1894400548a9.mp4",
          startTime: 137
        },
        {
          id: 6,
          name: "飞鹰战术发射器",
          src: require("@/assets/images/observe/009.svg"),
          外形尺寸: "220mm*135mm*260mm",
          外包装箱: "473mm*348mm*185mm",
          净重: "3.85KG",
          材质: "POM赛钢、航空铝、碳纤维、不锈钢材质",
          安装方式: "快拆式",
          发射方式: "电激发",
          口径尺寸: "38mm",
          装弹容量: "8枚",
          供电模式: "机载电源",
          工作电压: "DC 12-28V",
          控制方式: "遥控器和平台操作",
          控制距离: "10km",
          控制角度: "俯仰轴0-90度",
          适用机型: "入云龙II",
          摄像头: "有效像素：PAL：976（H）*494（V）；NTSC:768（H）*494（V）",
          video_url:"https://32.128.6.48/uploads/2023/09/09/e217b32e-56b1-4176-8706-01806b147614.mp4",
          startTime: 175
        },
        {
          id: 7,
          name: "飞鹰喷火器",
          src: require("@/assets/images/observe/008.png"),
          型号: "霹雳火FF6",
          尺寸: "1400mm*320mm*280mm（展开）900mm*320mm*280mm（折叠）",
          重量: "产品重量3.2kg",
          燃料: "95汽油",
          最大容量: "≤5L",
          点火方式: "高压脉冲点火",
          喷头角度可调范围: "俯仰±35°，方向±35",
          燃烧距离: "4-6米",
          支持机型: "入云龙2",
          使用环境: " -30~40℃",
          video_url:"https://32.128.6.48/uploads/2023/09/09/37e6ff8a-7cf5-4601-b489-07aeda20cc57.mp4",
          startTime: 172
        },
        {
          id: 8,
          name: "飞鹰干粉灭火器",
          src: require("@/assets/images/observe/019.png"),
          主体重量: "5.5kg(其中干粉3kg)",
          工作温度: "-10℃～55℃",
          尺寸: "φ250*350mm",
          防护级别: "IP43",
          工作电压: "12V",
          发射角度: "竖直向下喷射",
          发射距离: "9m²",
          控制方式: "遥控器、平台",
          video_url:"https://32.128.6.48/uploads/2023/09/04/f5f938b1-6b60-4c92-9ccd-ffad4297903e.mp4",
          startTime: 208
        },
        {
          name: "飞鹰破窗灭火弹",
          发射器外管径: "Φ67mm",
          src: require("@/assets/new_yingji/pcq.svg"),
          id: 9,
          最大外管口径: "Φ70mm",
          发射器全长: "1000mm",
          发射器全重: "≤1400g",
          发射器主体材质: "复合材料，合金",
          发射器触发电压: "12v-24v电信号",
          发射器电流: "≥700ma",
          发射器使用温度: "-40℃—﹢50℃",
          发射器使用年限: "5年≥200次",
          video_url:"https://32.128.6.48/uploads/2023/09/09/66964085-327d-43a6-bf37-ead992b1d42d.mp4",
          startTime: 210
        },
        {
          name: "飞鹰抓捕网",
          网面积: "3m*3m",
          src: require("@/assets/new_yingji/wangqiang.svg"),
          id: 10,
          发射角度: "30°",
          有效距离: "15m",
          重量: "1.2kg",
          供电电压: "12v-36v",
          供电方式: "无人机取电",
          挂载方式: "拔插式设计，可快速拆卸",
          触发方式: "舵机触发",
          控制方式: "遥控器、平台",
          video_url:"https://32.128.6.48/uploads/2023/09/12/0b626696-2a0d-4b07-a767-6503082194d8.mp4",
          startTime: 153
        },
        {
          name: "飞鹰强声驱散器",
          尺寸: "225*272*221",
          src: require("@/assets/new_yingji/qsq.svg"),
          id: 11,
          重量: "2.5kg",
          功率: "最大200w",
          最大声压: "140dB",
          有效广播距离: "1000m",
          指向性: "80°x50 °（水平 x 垂直）",
          工作温度: "-20—40℃",
          通信链路: "LTE网络链路、无人机专网",
          俯仰角度: "0-90度",
          音频格式: "mp3/wav/m4a/flac/aac",
          喊话方式:
            "实时喊话、录音上传、音频文件播放、背景音乐（自动闪避）、文字转语音（支持多种方言）",
          video_url:"https://32.128.6.48/uploads/2023/09/09/09514d59-42ca-4b55-ba48-bb0be8c6226f.mp4",
          startTime: 203
          
        },
        {
          name: "飞鹰抛投器",
          src: require("@/assets/images/observe/020.png"),
          id: 12,
          重量: "860g",
          工作温度: "-20°C~60°",
          尺寸: "130*120*138.6mm",
          抛投功能: "智能，手动 ",
          工作电压: "12V",
          角度调节范围: "0~90°",
          最大载重: "6Kg",
          控制方式: "遥控器、平台",
          video_url:"https://32.128.6.48/uploads/2023/09/04/570f1dcc-b6b0-4e49-8f8a-2afc69cb08fe.mp4",
          startTime: 202
        },
      ],
      infoList: {},
      infoLists: [],
      show: true,
      activeIndex: 0,
      scrollHeight: [],
      loadNum: 0,
      copyList: [],
      timer: null,
    };
  },
  methods: {
    //没有取水器的情况
    changeList(e) {
      let index = e
      if(e == 5){
        index = 13
      }else if(e == 4){
        index = 12
      }else if(e == 3){
        index = 11
      }
      this.list.forEach((val) => {
         if (val.id == index) {
          this.showInfo(val);
        }
      });
    },
    getSwiper() {
      var mySwiper = new Swiper(".swiper-container", {
        slidesPerView: 5,
        // spaceBetween: 30,
        direction: "vertical",
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 5000, //1秒切换一次
          pauseOnMouseEnter: true,
        },
        slideToClickedSlide: true,
        mousewheel: true,
        // on: {
        //   slideChange: function () {
        //     console.log(this.activeIndex,this);
        //     // alert(this.activeIndex);
        //     // this.changeList(this.activeIndex)
        //   },
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
      mySwiper.on("slideChangeTransitionEnd", () => {
            this.changeList(mySwiper.activeIndex)
        
      })
      //6.6.2之前的版本需要通过代码实现此功能
      mySwiper.el.onmouseover = function () {
        mySwiper.autoplay.stop();
      };
      mySwiper.el.onmouseout = function () {
        mySwiper.autoplay.start();
      };
      this.$refs.rightRef.onmouseover = function () {
        mySwiper.autoplay.stop();
      };
      this.$refs.rightRef.onmouseout = function () {
        mySwiper.autoplay.start();
      };
      
      this.$refs.uavImg.onmouseover = function () {
        mySwiper.autoplay.stop();
      };
      this.$refs.uavImg.onmouseout = function () {
        mySwiper.autoplay.start();
      };
    },
    showInfo(item) {
      this.infoLists = [];
      this.infoList = item;
      this.show = true;
      for (let i in this.infoList) {
        if (i != "id" && i != "name" && i != "src" && i != "video_url") {
          let arr2 = [];
          arr2.push(i);
          arr2.push(this.infoList[i]);
          this.infoLists.push(arr2);
        }
      }
    },
  },

  watch: {
    infoList:{
      handler(val){
        console.log(val,"valvllalvlal");
        setTimeout(() => {
          let videoItem = document.getElementById("mmcvideo");
          if(val.startTime){
            videoItem.currentTime = val.startTime
            videoItem.play()
          }else{
            videoItem.play()
          }
        }, 1000);
      }
    }
  },
  created() {
    this.infoList = this.list[0];
    for (let i in this.infoList) {
      if (i != "id" && i != "name" && i != "src" && i != "video_url") {
        let arr2 = [];
        arr2.push(i);
        arr2.push(this.infoList[i]);
        this.infoLists.push(arr2);
      }
    }
  },
  mounted() {
    console.log(this.list,"ccccc");
    this.getSwiper();
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import url("./css/index.css");
.cont {
  margin-top: 76px;
  height: calc(100% - 88px);
  display: flex;
  align-items: center;
  color: #fff;
  overflow: hidden;

  .mount {
    width: 500px;
    height: calc(100% - 148px);
    margin-left: 20px;

    .mmmmm {
      width: 100%;
      height: 100%;
      position: relative;
      // background-color: #fff;

      .top,
      .down {
        // width: 500px;
        // height: 34%;
        position: absolute;
        z-index: 1;
      }
      .top {
        top: 0;
        // background-color: #ad81812d;
      }
      .down {
        bottom: 0;
        // background-color: #b1e4c44b;
      }
    }
    .div {
      height: 70%;
      // position: relative;
      // // transform-origin: -600px -40px;
      padding-bottom: 140px;
      padding-top: 80px;
      // box-sizing: border-box;

      /deep/.swiper-slide {
        // width: 500px;
        // height: 166px !important;
        margin-top: -40px;
        background: url("~@/assets/new_yingji/xu.png") no-repeat;
        display: flex;
        align-items: center;

        .text {
          padding: 1.5rem 0 0 2rem;
          width: 45%;
          transform: skew(0deg, -10deg);
          h2 {
            font-family: "YouSheBiaoTiHei";
          }
          p {
            font-size: 12px;
            line-height: 30px;
          }
        }

        .img {
          margin-top: -45px;
          width: 120px;
          height: 80px;

          img {
            // width: 110%;
            height: 110%;
          }
        }
      }
      .info1 {
        margin-top: -42px;
      }
      .active {
        height: 190px;
        background: url("~@/assets/new_yingji/shi.png") no-repeat;
        cursor: pointer;

        .text {
          width: 46%;
        }
        .img {
          margin-top: -60px;
          img {
            // width: 140%;
            height: 135%;
          }
        }
      }
      .xu {
        width: 480px;
      }
      .shang {
        height: 210px;
        margin-left: -10px;
        background: url("~@/assets/new_yingji/shang.png") no-repeat;
        background-size: 101% 142%;
        background-position-x: -54px;
        background-position-y: -14px;
      }
      .xia {
        height: 212px;
        margin-top: -114px;
        margin-left: -10px;
        background: url("~@/assets/new_yingji/xia.png") no-repeat;
        background-size: 100% 140%;
      }
    }
  }
  // 滚动条样式
  .div::-webkit-scrollbar {
    width: 0px;
    background-color: #333c57;
    height: 8px;
  }

  .uav_img {
    margin-top: -10rem;
    flex: 1;
    height: 389px;
    // width: 400px;

    .img {
      margin: 0 auto;
      text-align: center;
      width: 80%;
      height: 80%;
      // background: url('~@/assets/new_yingji/UAV.png') no-repeat;
      background-size: 100% 100%;
      img {
        // width: 100%;
        height: 100%;
      }
    }
    .uav_video{
      width: 100%;
      // height: 200px;
      // background: url("~@/assets/new_yingji/video.png") no-repeat;
      border: 2px solid rgba(36,87,190,1);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      video{
        width: 100%;
        // height: 100%;
      }
    }
  }

  .right{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .uav_video{
      width: 400px;
      // height: 200px;
      // background: url("~@/assets/new_yingji/video.png") no-repeat;
      border: 2px solid rgba(36,87,190,1);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      video{
        width: 100%;
        // height: 100%;
      }
    }
    .uav_info {
      margin-top: -60px;
      transform: scale(0.9);
      // margin-left: 80px;
      width: 464px;
      height: 692px;
      background: url("~@/assets/new_yingji/you.png") no-repeat;
      // padding: 30px;

      .title {
        text-align: right;
        padding-top: 2rem;
        padding-right: 3rem;
        font-family: "YouSheBiaoTiHei";
        font-size: 24px;
      }

      .info {
        margin-top: 20px;
        padding-left: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 170px;

        .info-text {
          width: 40%;

          div {
            display: flex;
            align-items: center;
            height: 30px;
            margin: 8px;
            background: url("~@/assets/new_yingji/bj_text.png");

            p {
              text-indent: 1rem;
              margin-right: 20px;
              font-family: "YouSheBiaoTiHei";
            }

            span {
              font-size: 14px;
              color: #e2e2e2;
            }
          }
        }

        .info-img {
          // width: 40%;
          margin-left: 1rem;

          img {
            width: 215px;
            height: 112px;
          }
        }
      }

      .mount_info {
        h3 {
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .mount_text {
          width: 455px;
          height: 300px;
          padding-left: 30px;
          box-sizing: border-box;
          overflow-y: auto;

          //   display: flex;
          //   align-items: center;
          div {
            display: flex;
            align-items: center;
            height: 30px;
            margin: 8px;
            background: url("~@/assets/new_yingji/bj_text.png");
            background-size: 100% 100%;

            p {
              text-indent: 1rem;
              margin-right: 20px;
              font-family: "YouSheBiaoTiHei";
              white-space: nowrap;
            }

            span {
              font-size: 14px;
              color: #e2e2e2;
            }
          }
        }
      }
    }
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 500px;
  height: 166px;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: -webkit-flex;
  // display: flex;
  // -webkit-box-pack: center;
  // -ms-flex-pack: center;
  // -webkit-justify-content: center;
  // justify-content: center;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // -webkit-align-items: center;
  // align-items: center;
  transition: 300ms;
  transform: scale(0.7);
  background: url("~@/assets/new_yingji/xu.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .text {
    // padding: 1.5rem 0 0 2rem;
    width: 65%;
    transform: skew(0deg, -10deg);
    h2 {
      font-family: "YouSheBiaoTiHei";
    }
    p {
      font-size: 12px;
      line-height: 30px;
    }
  }

  .img {
    margin-top: -40px;
    width: 120px;
    height: 70px;

    img {
      // width: 110%;
      height: 100%;
    }
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  background: url("~@/assets/new_yingji/shi.png") no-repeat;
  background-size: 100% 100%;
        cursor: pointer;

        // .text {
        //   width: 46%;
        // }
        // .img {
        //   margin-top: -60px;
        //   img {
        //     // width: 140%;
        //     height: 135%;
        //   }
        // }
}
</style>
